<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo mt-2 mt-md-0">
        <b-img
          src="@/assets/images/simiicons/Logo.svg"
          alt="logo"
        />
      </b-link>
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg p-2 p-lg-2"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <span
            class="heading1"
          >
            Complete Profile
          </span>
          <div class="subheading mt-2 mt-md-0">
            Make your app management easy and fun!
          </div>
          <div class="d-inline-block d-flex justify-content-center align-content-center align-items-center mt-1 ml-1">
            <b-img
              :src="userImgSrc ? userImgSrc : require('@/assets/images/simiicons/signup.svg')"
              alt="data.json"
              rounded="circle"
              :class="userImgSrc ? 'userimg' : null "
            />
            <span class="upload">
              <b-button
                variant="link"
                class="icon-class"
                @click="inputFileClicked"
              >
                <b-img
                  :src="require('@/assets/images/simiicons/signupbtn.svg')"
                  rounded
                  alt="data.json"
                />
              </b-button>
            </span>
          </div>
          <!-- form -->
          <validation-observer ref="registerForm">
            <b-form class="auth-register-form mt-2">
              <input
                id="fileUpload"
                type="file"
                accept="image/*"
                hidden
                @change="uploadImage($event)"
              >
              <b-form-group
                label="Gender"
                class="input-label"
                label-for="select-gender"
              >
                <b-form-select
                  id="select-gender"
                  v-model="dataToBeSaved.gender"
                  :options="genderOptions"
                />
              </b-form-group>
              <b-form-group
                label="Date of Birth"
                class="input-label"
                label-for="example-datepicker"
              >
                <datepicker
                  v-model="dataToBeSaved.date_of_birth"
                  placeholder="Select Date"
                />
              </b-form-group>
              <b-form-group
                label="Date of Birth"
                class="input-label"
                label-for="example-datepicker"
              >
                <b-form-datepicker
                  id="example-datepicker"
                  v-model="dataToBeSaved.date_of_birth"
                  placeholder="Please select your date of birth"
                  initial-date="1990-01-01"
                  class="mb-1"
                  :date-disabled-fn="dateDisabled"
                />
              </b-form-group>
              <div class="form-group block">
                <b-button
                  variant="primary"
                  block
                  type="submit"
                  :disabled="!active"
                  @click.prevent="completeProfileOfUser"
                >
                  <div
                    v-if="spinner"
                    class="spinner"
                  >
                    <b-spinner
                      small
                    />
                    <span class="sr-only">Loading...</span>
                  </div>
                  Create Account
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormGroup,
  BImg,
  BSpinner,
  BFormSelect,
  BFormDatepicker,
} from 'bootstrap-vue'
import { required } from '@validations'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'
import store from '@/store'
import { getFormDataFromJSON } from '@/common/global/functions'

export default {
  name: 'CompleteProfile',
  components: {
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BFormGroup,
    BSpinner,
    BFormSelect,
    BFormDatepicker,
    Datepicker,
    // validations
    ValidationObserver,
  },
  beforeRouteEnter(to, from, next) {
    const user = store.getters['user/getUser']
    if (user.is_profile_completed) {
      next({
        name: 'dashboard',
      })
      return
    }
    next()
  },
  data() {
    return {
      username: '',
      userEmail: '',
      userImage: '',
      userImgSrc: '',
      password: '',
      confirmPassword: '',
      active: true,
      spinner: false,
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      required,
      dataToBeSaved: {
        gender: null,
        date_of_birth: null,
        image: null,
      },
      genderOptions: [{
        value: null,
        text: 'Please select your gender',
        disabled: true,
      },
      {
        value: 1,
        text: 'Male',
      },
      {
        value: 2,
        text: 'Female',
      },
      {
        value: 3,
        text: 'Others',
      },
      ],
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    dateDisabled(ymd, date) {
      return moment(date).format('YYYY-MM-DD') >= moment(Date.now()).format('YYYY-MM-DD')
    },
    inputFileClicked(event) {
      document.getElementById('fileUpload').click(event)
    },
    uploadImage(event) {
      const obj = event.target.files[0]
      this.dataToBeSaved.image = obj
      this.userImgSrc = URL.createObjectURL(obj)
    },
    async completeProfileOfUser() {
      this.spinner = true
      try {
        const formData = getFormDataFromJSON(this.dataToBeSaved)
        const { data: { data } } = await this.$axios.post('user/complete-profile', formData)
        await this.$store.dispatch('user/setUser', data)
        await this.$router.push('/create-group')
      } catch ({
        response: {
          data: {
            statusCode, message,
          },
        },
      }) {
        this.$swal(message.toString())
      }
      this.spinner = false
    },
  },
}
</script>

<style scoped lang="scss">
.subheading{
  font-size: 14px;
  line-height: 30px;
  color: #3d3d3d;
  font-weight: 400;
  font-family: "Montserrat";
}
.heading1{
  width: 277px;
  height: 30px;
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 500;
  font-family: "Montserrat";
}
.upload{
  margin-left: -30px;
  margin-bottom: -40px;
}
.icon-class{
  padding: 0px !important;
}
.input-label {
  font-size: 10px;
  line-height: 18px;
  color: #323232;
  font-weight: 600;
  font-family: "Montserrat";
}
.userimg{
  width: 140px;
  height: 140px;
}
</style>
